'use client';

import { ReactNode } from 'react';

import { ImpressionWrapper } from '@/components/ImpressionWrapper';
import { SystemModuleType } from '@/types/types';

export interface TournamentHomeCardProps {
	isRecommendedTournament?: boolean;
	image: string;
	title: string;
	duration?: string;
	slug?: string;
	tournamentId: string;
	location?: string;
	label?: ReactNode;
	registrationCount?: number;
	isPromoted?: boolean;
	isFavorite?: boolean;
}

export const FeaturedTournamentCard = ({ title, image, duration, location, tournamentId }: TournamentHomeCardProps) => {
	return (
		<ImpressionWrapper moduleId={tournamentId} moduleFor={SystemModuleType.FEATURED_TOURNAMENT}>
			<div className="group flex h-[120px] !size-full min-[500px]:min-w-[343px] min-[500px]:max-w-[395px] flex-row flex-nowrap rounded-lg border bg-white">
				<div className="flex w-full flex-row justify-center">
					<div className="relative m-auto h-[80px] !rounded-lg" style={{ aspectRatio: '16 / 9' }}>
						<img src={image} alt={title} data-nmig="fill" className="absolute inset-0 !size-full rounded-lg object-contain" />
					</div>
					<div className="flex w-2/3 flex-col justify-center gap-1 !rounded-r-lg border-l border-l-gray-200 p-4 group-hover:bg-gray-50 md:w-2/3">
						<div className="!line-clamp-1 text-sm text-gray-700">{duration}</div>
						<div className="!line-clamp-2 block text-sm font-medium text-gray-900" style={{ minHeight: '2rem' }}>
							{title}
						</div>
						{location && <div className="line-clamp-1 text-sm text-gray-600"> {location} </div>}
					</div>
				</div>
			</div>
		</ImpressionWrapper>
	);
};
