import(/* webpackMode: "eager", webpackExports: ["FullSearchForm"] */ "/app/apps/tournament/src/components/FullSearchForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeaturedContainer"] */ "/app/apps/tournament/src/components/homepage/FeaturedContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderSection"] */ "/app/apps/tournament/src/components/homepage/SliderSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImpressionWrapper"] */ "/app/apps/tournament/src/components/ImpressionWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileSearchHeader"] */ "/app/apps/tournament/src/components/MobileSearchHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Partners"] */ "/app/apps/tournament/src/components/Partners.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/PreSearchSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/tournament/src/components/UpcomingTournamentModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Ticker"] */ "/app/apps/tournament/src/modules/ticker/Ticker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RTEProvider"] */ "/app/apps/tournament/src/providers/rte/RTEProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PPATourneysCarousel"] */ "/app/apps/tournament/src/tournament/components/PPATournamentCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PTBlogSection"] */ "/app/apps/tournament/src/tournament/components/PTBlogSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.9_@opentelemetry+api@1.9.0_@playwright+test@1.51.0_react-dom@18_ggr6uiu3yz7mi55wgk437hasfu/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["SearchHeader"] */ "/app/packages/features/src/tournaments-app/search-header/components/SearchHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/src/components/advertisements/components/advertisement-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/src/components/pickleball-app/Header/MainHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/src/components/pickleball-app/TopNavigation.tsx");
