import CustomLink from '@pickleballinc/ui/components/ui/custom-link';
import React, { useState } from 'react';

import FavoriteButton from '@/components/Favorite/FavoriteButton';
import { useSession } from '@/lib/SessionProvider';
import { FeaturedTournamentCard, TournamentHomeCardProps } from '@/tournament/components/cards/FeaturedTournamentCard';

interface FavoriteTournamentCardProps extends TournamentHomeCardProps {
	moduleUuid: string;
}

const FavoriteFeaturedTournamentCard = ({ moduleUuid, ...tournamentProps }: FavoriteTournamentCardProps) => {
	const handleCardClick = async (e: React.MouseEvent, slugOrTournamentId: string) => {
		e.preventDefault();
		const url = `${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${slugOrTournamentId}`;
		setTimeout(() => {
			window.location.href = url;
		}, 100);
	};

	const [isFavorited, setIsFavorited] = useState(tournamentProps.isFavorite || false);
	const { session } = useSession();

	return (
		<div className="relative min-[500px]:min-w-[343px] min-[500px]:max-w-[395px]">
			{process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && session?.user?.uuid && (
				<div className="absolute right-2 top-2 z-10">
					<FavoriteButton moduleUuid={moduleUuid} isFavorited={isFavorited} setIsFavorited={setIsFavorited} />
				</div>
			)}
			<CustomLink
				key={tournamentProps.tournamentId}
				onClick={(e) => handleCardClick(e, tournamentProps.slug || tournamentProps.tournamentId)}
				href={`/tournaments/${tournamentProps.slug || tournamentProps.tournamentId}`}
			>
				<FeaturedTournamentCard {...tournamentProps} />
			</CustomLink>
		</div>
	);
};

export default FavoriteFeaturedTournamentCard;
