'use client';

import { Button } from '@pickleballinc/react-ui';
import getPBracketsCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getPBracketsCDNURL';
import moment from 'moment';

import FavoriteFeaturedTournamentCard from '@/tournament/components/cards/FavoriteFeaturedTournamentCard';
import { FeaturedTournamentsRes } from '@/utils/actions/getFeaturedTournaments';

interface FeaturedContainerProps {
	featuredTournaments: FeaturedTournamentsRes;
	initialFeaturedTournamentsCount: number;
}

export const FeaturedContainer = ({ featuredTournaments, initialFeaturedTournamentsCount }: FeaturedContainerProps) => {
	return (
		<>
			{featuredTournaments && featuredTournaments.result.length > 0 && (
				<div className="bg-white py-8">
					<div className="mx-auto flex max-w-screen-2xl flex-row items-baseline justify-between p-4">
						<h1 className="text-2xl font-bold text-gray-900" tabIndex={0}>
							Featured Tournaments
						</h1>
						{featuredTournaments.totalRecords >= initialFeaturedTournamentsCount && (
							<a href={`${process.env.NEXT_PUBLIC_PT_URL}/search?featured=true`}>
								<Button className="!text-blue-500" variant="link">
									Show all
								</Button>
							</a>
						)}
					</div>
					<div className="mx-auto max-w-screen-2xl items-stretch gap-4 p-4 md:gap-8 grid auto-rows-fr grid-cols-1 py-8 min-[740px]:grid-cols-2 min-[1160px]:grid-cols-3">
						{featuredTournaments.result.map((tourney) => (
							<FavoriteFeaturedTournamentCard
								key={tourney.TournamentID}
								image={getPBracketsCDNURL(tourney.Logo, 243, 192)}
								title={tourney.Title}
								duration={`${moment.parseZone(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
									.parseZone(tourney.TourneyToDate)
									.format('MMM DD, YYYY')}`}
								location={`${tourney.LocationCity}, ${tourney.LocationState}, ${tourney.LocationCountry}`}
								tournamentId={tourney.TournamentID}
								isFavorite={tourney.isFavorite}
								moduleUuid={tourney.TournamentID}
							/>
						))}
					</div>
				</div>
			)}
		</>
	);
};
